import React, { useContext } from 'react';
import ScrollableAnchor from 'react-scrollable-anchor';
import { Grid, Row, Col } from 'react-flexbox-grid';

import { appContext } from 'providers/appProvider';
import { Layout, Seo } from 'components';
import ContentBlock from 'components/Content/ContentBlock';
import HeadlineBar from 'components/Content/HeadlineBar';

// import page style
import './why.scss';

const WhyPage = () => {
  const { appConfigs } = useContext(appContext);
  const indication = appConfigs?.siteOptions?.INDICATIONS?.hcp;
  const metaData = appConfigs?.metaData?.hcp;

  const brandNames = {
    NASCOBAL: appConfigs?.metaData?.brandNames?.nascobal,
    BARIACTIV: appConfigs?.metaData?.brandNames?.bariactiv,
    NUTRITION: appConfigs?.metaData?.brandNames?.nutrition,
  };

  const renderPage = () => {
    return (
      <div id="nascobal-hcp-why">
        <ContentBlock id="hcp-why-block">
          <Grid
            fluid
            style={{ paddingLeft: 0, paddingRight: 0, paddingTop: 0 }}
          >
            <Row>
              <Col xs={12}>
                <HeadlineBar blue>
                  WHY{' '}
                  <span className="yellow">
                    <span
                      className="brandname-nascobal"
                      dangerouslySetInnerHTML={{
                        __html: brandNames?.NASCOBAL,
                      }}
                    />
                  </span>
                </HeadlineBar>
              </Col>
            </Row>
          </Grid>
        </ContentBlock>
        <ScrollableAnchor id="isi_anchor">
          <div />
        </ScrollableAnchor>
      </div>
    );
  };

  return (
    <Layout indication={indication} className="hcp-why">
      <Seo {...metaData} />
      <div className="body-content-container">
        <div className="body-content gutter-all">{renderPage()}</div>
      </div>
    </Layout>
  );
};

export default WhyPage;
